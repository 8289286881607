import React from 'react'
import Layout from '../components/Layout'
//Illustration
import notFoundIllustration from '../assets/company/404-error-illustration.svg'

const NotFound = () => {
    return (
        <Layout>
        <div className='not_found_container'>
            <img className='not_found_container_img' src={notFoundIllustration} alt='404 Not Found'/>
        </div>
        </Layout>
    )
}

export default NotFound
